// 搜索项
export const SEARCH_CHECKED_KEY = 'searchChecked';
export const SEARCH_SLIDER_KEY = 'searchSlider';

// 播放器
export const PLAYER_KEY = 'playerConfig';

// 设置项
export const SETTING_KEY = 'setting';
export const SETTING_DEFAULT_SERVER = 'auto';
